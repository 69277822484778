// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contact-us-thank-you-tsx": () => import("./../../../src/pages/contact-us-thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-us-thank-you-tsx" */),
  "component---src-pages-details-tsx": () => import("./../../../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-event-registration-thank-you-tsx": () => import("./../../../src/pages/event-registration-thank-you.tsx" /* webpackChunkName: "component---src-pages-event-registration-thank-you-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-community-tsx": () => import("./../../../src/pages/join-community.tsx" /* webpackChunkName: "component---src-pages-join-community-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-services-business-health-check-tsx": () => import("./../../../src/pages/services/business-health-check.tsx" /* webpackChunkName: "component---src-pages-services-business-health-check-tsx" */),
  "component---src-pages-services-customised-support-tsx": () => import("./../../../src/pages/services/customised-support.tsx" /* webpackChunkName: "component---src-pages-services-customised-support-tsx" */),
  "component---src-pages-services-hatch-pacific-tsx": () => import("./../../../src/pages/services/hatch-pacific.tsx" /* webpackChunkName: "component---src-pages-services-hatch-pacific-tsx" */),
  "component---src-pages-services-online-community-tsx": () => import("./../../../src/pages/services/online-community.tsx" /* webpackChunkName: "component---src-pages-services-online-community-tsx" */),
  "component---src-pages-services-procurement-tsx": () => import("./../../../src/pages/services/procurement.tsx" /* webpackChunkName: "component---src-pages-services-procurement-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-workshops-tsx": () => import("./../../../src/pages/services/workshops.tsx" /* webpackChunkName: "component---src-pages-services-workshops-tsx" */),
  "component---src-pages-sp-registration-thank-you-tsx": () => import("./../../../src/pages/sp-registration-thank-you.tsx" /* webpackChunkName: "component---src-pages-sp-registration-thank-you-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/legal-page.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-news-details-template-tsx": () => import("./../../../src/templates/news-details-template.tsx" /* webpackChunkName: "component---src-templates-news-details-template-tsx" */)
}

